/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import maintenance from '../../assets/roleErreur.png';

const RoleErreur = () => {
    return (
        <div className="flex alignICenter p20">
            <img src={maintenance} alt="" className="w20" />
            <div>
                <h1 className="">Vous n'etes pas autoriser à acceder à cette page</h1>
                <Link to="/accueil">
                    <p>Revenir vers l'acceuil</p>
                </Link>
            </div>
        </div>
    );
};

export default connect(null, null)(RoleErreur);
