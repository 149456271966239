/* eslint-disable react-hooks/exhaustive-deps */
/**
 * liste des groupes de rôle
 *
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Container from '../../components/atoms/Container/container';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import Button from '../../components/atoms/ButtonCustom';
import { GET, DELETE } from '../../components/Utils/requete';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';
import { testDroit } from '../../components/Utils/testDroit';
import { Gestion_de_rôle } from '../../fonctionsRoles/accesURL';

const ListeGroupe = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    const [listeGroupeRole, setListeGroupeRole] = useState([]);
    const [listeSupprimable, setListeSupprimable] = useState([]);
    const tableHeadListeResponsable = [{ label: 'Groupe' }, { label: "Nombre d'utilisateur" }, { label: 'Description' }];

    async function requeteListeResponsable() {
        const response = await GET(`/api/gestionRole/listeGroupe`, undefined, {
            redirectOnError: false,
        });
        setListeGroupeRole(response.data);
        const listeGroupeSupprimable = response.data.filter((e) => e.supprimable === 0);
        const libelleGroupe = [];
        for (let index = 0; index < listeGroupeSupprimable.length; index++) {
            libelleGroupe.push(listeGroupeSupprimable[index].libelleGroupe);
        }
        setListeSupprimable(libelleGroupe);
    }
    async function deleteGroupe(idGroupe) {
        const response = await DELETE(`/api/gestionRole/${idGroupe}`);
        if (response.success) {
            requeteListeResponsable();
        }
    }

    useEffect(() => {
        props.addNomPage('Liste des groupe de rôles');
        requeteListeResponsable();
    }, []);

    if (window.matchMedia('(max-width: 576px)').matches || window.matchMedia('(max-width: 1080px)').matches) {
        return <p className="textCenter ">Vous ne pouvez pas acceder à cette page sur mobile</p>;
    }
    if (!testDroit(user[usurpation].roleFonction, Gestion_de_rôle)) return window.location.replace('/role-erreur');
    return (
        <div className="listeContainer">
            <Container>
                <Link to="/groupe-role/0">
                    <Button variant="outlined" size="small" className={[css.mb5, css.floatRight]}>
                        Créer un Groupe
                    </Button>
                </Link>
                <p>
                    Pour supprimer un groupe vous devez transférer les agents de ce groupe vers un autre groupe. <br />
                    Les groupes <span style={{ color: 'blue' }}>{listeSupprimable.join(', ')}</span> ne sont pas supprimables.
                    <br />
                    Le groupe <span style={{ color: 'blue' }}>Superviseur</span> n'est pas modifiable.
                </p>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHeadListeResponsable}></TableHeadCustom>
                        <TableBody>
                            {listeGroupeRole.map((row, index) => {
                                return (
                                    <TableRow key={index} className={css.trbody}>
                                        <TableCell className={[css.td, css.w35]} component="th" scope="row">
                                            <CustomTooltips title="Éditer le groupe">
                                                <Link to={`/groupe-role/${row.idGroupe}`}>{row.libelleGroupe}</Link>
                                            </CustomTooltips>
                                            {row.supprimable === 1 && +row.nombreUtilisateur === 0 ? (
                                                <IconButton
                                                    color="secondary"
                                                    className={[css.p0, css.mr2, css.floatRight]}
                                                    onClick={() => deleteGroupe(row.idGroupe)}
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            ) : null}
                                        </TableCell>
                                        <TableCell className={[css.td, css.w20]} component="th" scope="row">
                                            {row.nombreUtilisateur}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            {row.commentaire}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
    };
}

export default connect(null, mapDispatchToProps)(ListeGroupe);
